<template>
  <div class="max750">
    <!-- section-1  -->
    <v-container >
      <v-row class="mb-6" >
        <v-col cols="12" xl="12" lg="12">
          <div>
          <v-img
              class="flex-inherit"
              relative
              width="1200"
              src="@/assets/images/detail/invite-title-bg.png"
            >
                <div class="invite-title">
                    <v-btn  rounded color="#FEE487" depressed class="anred font-weight-bold">{{$t('每成功邀请一个好友')}}</v-btn>
                    <div class="d-flex align-center mt-10 mb-10">
                        <span class="text-h3 white--text" >{{$t('终身获得')}}</span>
                        <span class="text-h3 textyellow ">10%</span>
                        <span  class="text-h3   white--text">{{$t('邀请收益')}}</span>
                    </div>
                    <p class="  white--text">{{$t('不仅省钱，还能赚钱，快来一起行动吧！')}}</p>
                </div>
                <div class="d-flex justify-space-around" style="margin-top:110px">
                    <span class="items ">
                        <p>{{$t('分享链接给好友')}}</p>
                        <p>{{$t('好友完成注册')}}</p>
                    </span>
                    <span class="items  items-1">
                        <p>{{$t('好友购买返利商品')}}</p>
                        <p>{{$t('好友确认收货')}}</p>
                    </span>
                    <span  class="items  items-2">
                        <p>{{$t('获得返利佣金')}}</p>
                        <p>{{$t('佣金打入您的账户')}}</p>
                    </span>
                </div>
            </v-img>
            </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- 二楼 -->
    <div class="container mb-60 baojia-box liucheng-top rounded-lg">
        <v-card 
            flat
            class="bannerlist rounded-12 mt-4 pa-4 d-lg-block"
        >
            <v-row>
                <v-col cols="12">
                    <div class="text-center d-flex align-center justify-center mb-4">
                        <div>
                            <v-img
                            class="flex-inherit"
                            width="28"
                            src="@/assets/images/icon/icon_red_left.svg"
                            ></v-img>
                        </div>
                        <span class="font-600 ml-4 mr-4 text-body-1 font-weight-bold">
                        {{ $t("如何所得返利？") }}
                        </span>
                        <div>
                            <v-img
                            class="flex-inherit mr-4"
                            width="30"
                            src="@/assets/images/icon/icon_red_right.svg"
                            ></v-img>
                        </div>
                    </div>
                </v-col>
               
                <v-col cols="12">
                    <v-row class="justify-center">
                      <v-col cols="12" xl="6" lg="6" md="12" sm="12"> 
                        <v-card color="#F7F7F7" flat class="d-flex align-center pa-4">
                            <div class="relative">
                                <v-img width="182" src="@/assets/images/detail/red-box.png">
                                  <span class="absolute white--text">{{$t('第一步')}}</span>
                                </v-img>
                            </div>
                            <div class="ml-4" >
                                <p class="text-body-1 font-weight-bold  mb-4">{{$t('分享注册码')}}</p>
                                <p>{{$t('复制注册码给好友，好友注册时填写该注册码，立即成为您的关联用户')}}</p>
                                <v-card
                                
                                    class=" mt-4 rounded-pill d-md-flex  align-center justify-space-between"
                                    flat
                                    rounded
                                    color="#FDD7CD"
                                    max-width="250"
                                >
                                    <span  v-if="userinfo && userinfo.code" class="pl-3 primary--text font-weight-bold text-h5">{{userinfo.code}}</span>
                                    <v-btn
                                    color="primary"
                                    @click="copyFn(userinfo.code)"
                                    rounded
                                    depressed
                                    height="42"
                                    class="ml-3 text-body-1"
                                    >{{ $t("复制注册码") }}</v-btn
                                    >
                                </v-card>
                            </div>

                        </v-card>
                      </v-col>
                      <v-col cols="12"  xl="6" lg="6" md="12" sm="12"> 
                        <v-card color="#F7F7F7" flat class="d-flex align-center pa-4">
                            <div class="relative">
                                <v-img width="182" src="@/assets/images/detail/red-box.png">
                                  <span class="absolute white--text">{{$t('第二步')}}</span>
                                </v-img>
                            </div>
                            <div class="ml-4" >
                                <p class="text-body-1 font-weight-bold ">{{$t('分享二维码')}}</p>
                                <p>{{$t('复制您的专属二维码图片给好友，好友扫描该二维码注册，立即成为您的关联用户')}}</p>
                                <div class="d-flex  align-center">
                                  <span>

                                  <img :src="QRImgUrl" />
                                  </span>
                                  <v-btn
                                    color="primary"
                                    rounded
                                    depressed
                                    height="40"
                                    @click="downCode"
                                    class="ml-3 text-body-1"
                                    >{{ $t("下载二维码") }}</v-btn
                                    >
                                </div>
                            </div>
                        </v-card>
                      </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" >
                  <v-card color="#F7F7F7" flat class="d-flex align-center pa-4">
                    <div class="relative">
                        <v-img width="182" src="@/assets/images/detail/red-box.png">
                          <span class="absolute white--text">{{$t('第三步')}}</span>
                        </v-img>
                    </div>
                    <div class="ml-4">
                        <p class="text-body-1 font-weight-bold  mb-4">{{$t('分享邀请链接')}}</p>
                        <p>{{$t('分享您的专属注册意请链接给好友，好友通过链接注册，立即成为关联用户')}}</p>
                        <v-card
                            class=" mt-4 rounded-pill d-md-flex  align-center justify-space-between"
                            flat
                            color="#FDD7CD"
                            max-width="550"
                        >
                            <span class="pl-3 primary--text font-weight-bold">https://download.taocarts.com/taocartsapk/fanliapp.apk</span>
                            <v-btn
                            color="primary"
                            @click="copyFn('https://download.taocarts.com/taocartsapk/fanliapp.apk')"
                            rounded
                            depressed
                            height="42"
                            class="ml-3 text-body-1"
                            >{{ $t("复制链接") }}</v-btn
                            >
                        </v-card>
                    </div>
                </v-card>
                </v-col>
            </v-row>
            <div>
              <div class="text-center d-flex align-center justify-center mt-4">
                <div>
                    <v-img
                    class="flex-inherit"
                    width="28"
                    src="@/assets/images/icon/icon_red_left.svg"
                    ></v-img>
                </div>
                <span class="font-600 ml-4 mr-4 text-body-1 font-weight-bold">
                {{ $t("活动说明") }}
                </span>
                <div>
                    <v-img
                    class="flex-inherit mr-4"
                    width="30"
                    src="@/assets/images/icon/icon_red_right.svg"
                    ></v-img>
                </div>
              </div>
              <div>
                <p>①{{$t('收益计算')}}</p>
                <div  style="text-indent: 1em;">
                  <p>{{$t('·推广佣金 = 被推广人有效成交额 X 推广比例;')}}</p>
                  <p>{{$t('例: 推广有效成交额 (50000元) x 推广比例(0.01%) =推广佣金 (5元)')}}</p>
                  <p>{{$t('注:实际返现佣金比例可能会有小范围波动，具体以实际返现为准')}}</p>
                </div>
              </div>
              <div>
                <p>②{{$t('有效推广人定义')}}</p>
                <div  style="text-indent: 1em;">
                  <p>{{$t('·专属注册码邀请:当用户自主访问站点注册时，如果推广人提供他的“推广员用户注册码”给被推广人，那么在注册完成以后，也属于您的推广用户。')}}</p>
                  <p>{{$t('·专属二维码邀请:被推广人通过App扫描推广人二维码完成注册，此用户就属于您的推广用户')}}</p>
                  <p>{{$t('·专属链接邀请:被推广人通过访问专属邀请链接，会自动在他浏览跟注册页面填写“推广员用户账号”，然后完成注册，此用户就属于您的推广用户了。')}}</p>
                  <p>{{$t('注:不论是访问以上三种方式中任意哪种，均以最后访问的链接、推广码、二维码为最终推广人')}}({{$t('例:前面打开A的链接，后面又访过了B的链接，当通过B的等接注册成功，则为B的推广用户')}})</p>
                </div>
              </div>
              <div>
                <p>③{{$t('推广须知')}}</p>
                <div  style="text-indent: 1em;">
                  <p>{{$t('·推广过程禁止采用恶意代码强制捆绑或其它违反法律法规的任何非正当方式。')}}</p>
                  <p>{{$t('·当推广人/被推广人账户涉及举报、违规、违法行为，导致账户冻结，将停止推广权限以及佣金返利。')}}</p>
                  <p>{{$t('·推广层级，只限二级，不提供三级返利。')}}</p>
                </div>
              </div>
              <div>
                <p>④{{$t('结算')}}</p>
                <div  style="text-indent: 1em;">
                  <p>{{$t('·佣金累计至50元，可随时申请至你的支付宝或银行帐户。')}}</p>
                  <p>{{$t('·申请时间: 按照平台的寄售时间为准。')}}</p>
                </div>
              </div>
              <p class="primary--text" >{{$t('*本活动最终解释权在法律范围内归平台所有')}}</p>
            </div>
        </v-card>
    </div>
    <!-- 复制弹窗 -->
    <v-dialog v-model="isfuzhiDialog" class="white" width="400" persistent>
      <v-card class="py-10 rounded-lg">
        <v-card flat color="#F2F2F2" class="py-4 ml-7 mr-7">
          <p class="text-h5 text-center mb-0">{{ $t("复制成功") }}</p>
        </v-card>
        <p class="mt-3 mb-5 text-center text-caption text--disabled">
          {{ $t("赶紧发给好友注册吧~") }}
        </p>
        <v-row class="ma-0">
          <v-spacer />
          <v-btn
            class="primary mx-auto pl-6 pr-6"
            depressed
            @click="isfuzhiDialog = false"
            >{{ $t("确定") }}</v-btn
          >
          <v-spacer />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcode'

export default {
  name: 'invitindex',
  components: {

  },
  data() {
    return {
      colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
      isfuzhiDialog:false,
      userinfo:{code:"00001"},
      QRImgUrl:"https://download.taocarts.com/taocartsapk/fanliapp.apk",
      QRlink:'www.xxx.com'
    };
  },
  created(){
    this.$api.user.getUserInfo().then((res)=>{
       
    })
    if(this.$store.state.USERINFO){
      this.userinfo = this.$store.state.USERINFO;

    }
    
    this.getQRcode()
  },
  mounted() {},
  methods: {
    getQRcode(){
      let that=this
      let opts = {
        errorCorrectionLevel: "L",//容错级别
        type: "image/png",//生成的二维码类型
        quality: 0.3,//二维码质量
        margin: 1,//二维码留白边距
        width: 70,//宽
        height: 70,//高
        text: "https://download.taocarts.com/taocartsapk/fanliapp.apk",//二维码内容
        color: {
              dark: "#666666",//前景色
              light: "#fff"//背景色
        }
      };
  		QRCode.toDataURL(that.userinfo.code ? that.userinfo.code : '', opts , (err, url) => {
          if (err)throw err
          //将生成的二维码路径复制给data的QRImgUrl
          that.QRImgUrl= url
        })
    },

    // 复制二维码
    copyFn(data){
      const url = data;
      const oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.isfuzhiDialog = true;
      oInput.remove();
    },
    // 下载二维码
    downCode(){
      let base64String = this.QRImgUrl;
      const id = Number(
        Math.random().toString().substr(3, 5) + Date.now()
      ).toString(36);
      let blob = this.base64StringToBlob(base64String);
      let url = URL.createObjectURL(blob);
      let save_link = document.createElementNS(
        "http://www.w3.org/1999/xhtml",
        "a"
      );
      save_link.href = url;
      save_link.download = "邀请二维码" + id;
      save_link.click();
    },
    base64StringToBlob: function (base64String) {
      var arr = base64String.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
  },
};
</script>
<style>

</style>
<style lang="scss" scoped>
// p{padding: 0;margin: 0;}
.relative{
  position: relative;
}
p{margin-bottom: 4px;}
.absolute{
  position: absolute;
  bottom: 32px;
  left: 50px;
}
.textyellow{
    color: #FFE455;
}
.textred{
    color: #F63605;
}
.anred{
    color:#B0430F
}

.items{
    margin-left: -33px;
    text-align: center;
    font-weight: 600;
}
.items-1{
    margin-left: -71px;
}
.items-2{
    margin-left: -53px;
}
.invite-title{
    margin: 100px 0 0 80px;
}
@media (max-width: 800px) {
   .max750 {
    width: 750px !important;
  }
}
@media (max-width: 2150px) {
  .liucheng-top {
    margin-top: -30px;
  }
}
@media (max-width: 2000px) {
  .liucheng-top {
    margin-top: -70px;
  }
  .step-list li {
    &:not(:last-child)::after {
      width: 30px;
      right: -40px;
    }
  }
}

@media (max-width: 1904px) {
  .liucheng-top {
    margin-top: 10px;
  }
}

@media (max-width: 1264px) {
  .liucheng-top {
    margin-top: 30px;
  }
}
@media (max-width: 960px) {
  .liucheng-top {
    margin-top: 20px;
    background-color: transparent !important;
  }
}
@media (max-width: 600px) {
  .liucheng-top {
    margin-top: 20px;
    padding: 12px !important;
  }
  .head-content {
    padding: 0px !important;
    margin: 0px !important;
  }
  .head-title {
    margin-top: 10px !important;
    margin-bottom: -10px !important;
  }
  .border-jianbian-panel {
    background-color: #fff !important;
  }
  .searchMain-input {
    width: 80% !important;
  }
}
</style>
